import { render, staticRenderFns } from "./App.vue?vue&type=template&id=c2eabc20&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CReset: require('@chakra-ui/vue').CReset, CBox: require('@chakra-ui/vue').CBox, CColorModeProvider: require('@chakra-ui/vue').CColorModeProvider, CThemeProvider: require('@chakra-ui/vue').CThemeProvider})
