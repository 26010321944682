<template>
    <c-flex align="top" width="100%" pb="1">
        <c-box flexGrow="1">
            <c-flex alignItems="left">
                <c-box :width="['100%', '60%', '60%']">
                    <c-flex :direction="['column', 'row']">
                        <c-box>
                            <c-text width="70px" fontSize="l" color="green.400" pr="5">{{ year.year }}</c-text>
                        </c-box>
                        <c-box :width="['100%', '60%']">
                            <c-link @click="navigate(year)">
                                <c-text fontSize="l">{{ year[`common_name_${language}`] }}</c-text>
                            </c-link>
                        </c-box>
                        <c-box pr="5" :width="['100', '50%']">
                            <c-link @click="navigate(year)">
                                <c-text as="i" fontSize="l" color="gray.600">{{ year.scientific_name }}</c-text>
                            </c-link>
                        </c-box>
                    </c-flex>
                </c-box>
                <c-box :width="['100%', '40%', '40%']">
                    <c-flex :direction="['column', 'row']">
                        <c-box pr="5" :width="['100%', '50%']">
                            <c-text fontSize="l">{{ year[`taxonomic_group_${language}`] }}</c-text>
                        </c-box>
                        <c-box :width="['10%', '25%']">
                            <c-box 
                                width="100%"
                                max-width="100%" 
                            >   
                                <TextBadge 
                                    :color="color(year[`${fieldPrefix}_map_rank`]).font_color" 
                                    :bg="color(year[`${fieldPrefix}_map_rank`]).color"
                                    :rank="year[`${fieldPrefix}_rank`]"
                                >
                                    {{ year[`${fieldPrefix}_rank`] }}
                                </TextBadge>
                            </c-box>
                        </c-box>
                        <c-box flexGrow="1">
                            <c-text>{{ origin(year[`${fieldPrefix}_origin_code`]) }}</c-text>
                        </c-box>
                    </c-flex>
                </c-box>
            </c-flex>
            <c-divider />
        </c-box>
    </c-flex>
</template>

<script>

import {
    CBox, 
    CText, 
    // CStack, 
    CFlex,
    // CBadge,
    CDivider
} from '@chakra-ui/vue'

import RouterLink from 'vue-router'
import { map_rank_color, years, origin } from '../config'
import { mapState, mapGetters } from 'vuex'
import TextBadge from './TextBadge.vue'

export default {
    name: 'ResultItemYear',
    props: ['year', 'status', 'years'],
    components: {
        // eslint-disable-next-line
        RouterLink,
        CFlex,
        CBox,
        CText,
        // CBadge,
        TextBadge,
        CDivider
    },
    data () {
        return {
            originCodes: origin
        }
    },
    methods: {
        origin (value) {
            return value && this.originCodes[value][`label_${this.language}`]
        },

        color (value) {
            const item = map_rank_color[this.rowStatus][value]
            return item ? item : {color: 'black', font_color: 'white'}

        },
        navigate (data) {
            this.$store.dispatch('setSelectedSpecies', this.years)
            this.$router.push({
                name: 'SpeciesProfile',
                params: {
                    query_language: this.language,
                },
                query: {
                    code: data.code,
                    year: data.year
                }
            })
        }
    },
    computed: {
        ...mapState(['language', 'selected_year', 'selected_region']),
        ...mapGetters(['fieldPrefix']),
        rowStatus() {
            const rowYear = years.find(item => item.label === this.year.year )
            const status = rowYear?.status
            return status
        },
        
    }
}
</script>

<style>


</style>