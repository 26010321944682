<template>
  <c-box :p="5" :w="['350px']" overflow-y="scroll" height="calc(100vh - 168px)">
    <!-- Year selector -->
    <c-box paddingBottom="3">
      <c-text fontSize="md" pb="2">{{ label("report_year") }}:</c-text>
        <ais-menu-select 
          attribute="year" 
          :transform-items="setYear"
        >
          <template v-slot:defaultOption>
            {{ label("see_all")}}
          </template>
        </ais-menu-select>
    </c-box>

    <div v-if="selected_year">
      <!-- Region select -->
      <c-box>
        <region-select :language="language" :setRegion="setRegion" />
      </c-box>

      <!-- Taxonomic Group -->
      <c-box flex="1" text-align="left" pb="3" pt="3">
        {{ label("taxonomic_group") }}
        <c-divider />
        <ais-refinement-list
          show-more
          :show-more-limit="50"
          :attribute="`taxonomic_group_${language}`"
          operator="and"
          :transform-items="transformTaxonomic"
        >
          <template
            v-slot="{
              items,
              isShowingMore,
              canToggleShowMore,
              toggleShowMore,
              refine,
            }"
          >
            <ul class="ais-RefinementList-list">
              <li class="ais-RefinementList-item"  :class="item.isRefined ? 'ais-RefinementList-item--selected' : ''" v-for="item in items" :key="item.value">
                <label class="ais-RefinementList-label">
                    <input
                    type="checkbox"
                    class="ais-RefinementList-checkbox"
                    :checked="item.isRefined"
                    :value="item.value"
                    @click.prevent="refine(item.value)"
                  />
                    <span class="ais-RefinementList-labelText ">{{item.label}}</span>
                </label>
              </li>
            </ul>
            <button v-if="canToggleShowMore" @click="toggleShowMore">
              {{ !isShowingMore ? label("show_more") : label("show_less") }}
            </button>
          </template>
        </ais-refinement-list>
      </c-box>

      <!-- Rank -->
      <c-box flex="1" text-align="left" pb="3" v-if="isDisplayed('rank')">
        {{ label("rank") }}
        <c-divider />
        <ais-refinement-list
          :attribute="`${fieldPrefix}_map_rank`"
          :sort-by="['name:asc']"
          operator="and"
          :transform-items="transformRank"
        />
      </c-box>

      <!-- Origin -->
      <c-box flex="1" text-align="left" pb="3" v-if="isDisplayed('origin')">
        {{ label("origin") }}
        <c-divider />
        <ais-refinement-list
          :attribute="`${fieldPrefix}_origin_code`"
          :sort-by="['name:asc']"
          operator="and"
          :transform-items="transformOrigin"
        />
      </c-box>

      <!-- Migratory -->
      <c-box flex="1" text-align="left" pb="3" v-if="isDisplayed('migratory')">
        {{ label("migratory") }}
        <c-divider />
        <ais-refinement-list
          :attribute="`${fieldPrefix}_migratory`"
          :sort-by="['name:asc']"
          operator="and"
          :transform-items="transformBoolean"
        />
      </c-box>

      <!-- Priority -->
      <c-box flex="1" text-align="left" pb="3" v-if="isDisplayed('priority')">
        {{ label("priority") }}
        <c-divider />
        <ais-refinement-list
          attribute="priority"
          :sort-by="['name:asc']"
          operator="and"
          :transformItems="transformPriority"
        />
      </c-box>

      <!-- Trend / Description -->
      <c-box flex="1" text-align="left" pb="3" v-if="isDisplayed('trend')">
        {{ label("trend") }}
        <c-divider />
        <c-text>{{ label("description") }}</c-text>
        <ais-refinement-list
          :attribute="`description_code`"
          :sort-by="['name:asc']"
          operator="and"
          :transformItems="transformDescription"
        />
      </c-box>

      <!-- Reason -->
      <c-box pb="3" v-if="isDisplayed('trend')">
        <h3>{{ label("reason") }}</h3>
        <ais-refinement-list
          :attribute="`reason_code`"
          :sort-by="['name:asc']"
          operator="and"
          :transformItems="transformReason"
        />
      </c-box>
    </div>
  </c-box>
</template>

<script>
import {
  regions,
  labels,
  years,
  rank_codes,
  trend_description_codes,
  trend_reason_codes,
  boolean_codes,
  origin,
} from "../config";
import { taxonomic } from "../refinements";

import RegionSelect from "../components/RegionSelect.vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: {
    RegionSelect,
  },

  data() {
    return {
      years: years,
      taxonomic: taxonomic,
    };
  },

  methods: {
    ...mapActions(["setStoreYear", "setStoreRegion"]),
    label(label) {
      return labels[label][this.language];
    },

    // Set the year in ais-menu-select :attribute="rank.year
    setYear(items) {
      if (items.filter((item) => item.isRefined).length == 0)
        this.$store.dispatch("setStoreYear", null);
      return items.map((item) => {
        if (item.isRefined)
          this.$store.dispatch("setStoreYear", parseInt(item.label));
        if (item.label === 'See all') {
          return {
            ...item,
            label: 'test'
          }
        }
        return {
          ...item,
          label: item.label,
        };
      });
    },

    setRegion(items) {
      if (items.filter((item) => item.isRefined).length == 0)
        this.$store.dispatch("setStoreRegion", "Canada");
      return items.map((item) => {
        if (item.isRefined) this.$store.dispatch("setStoreRegion", item.label);
        return {
          ...item,
          label: item.label,
        };
      });
    },

    transformTaxonomic(items) {
      const taxData = taxonomic[this.selected_year][this.language];
      const taxArr = items.reduce((accum, item) => {
        if (taxData.includes(item.label)) {
          accum.push(item);
        }
        return accum;
      }, []);
      return taxArr.sort((a, b) => a.label.localeCompare(b.label));
    },

    transformOrigin(items) {
      return items.map((item) => ({
        ...item,
        label: origin[item.label][`label_${this.language}`],
      }));
    },

    // Apply string values to codes in refinement list.
    transformRank(items) {
      // Is item.label (the rank code) present in ais-state results
      // Build an array of values fromo results.filter(result) => this.selected_year === result[selected_year])
      // compare our values to this array to ensure they exist for the selected year

      // Filter current / Historical based on status, from year
      return items.reduce((accum, item) => {
        if (Object.keys(rank_codes[this.status]).includes(item.label)) {
          accum.push({
            ...item,
            label:
              rank_codes[this.status][item.label][`label_${this.language}`],
          });
        }
        return accum;
      }, []);
    },

    // Apply string values to codes in ref. list
    transformDescription(items) {
      return items.reduce((accum, item) => {
        if (
          Object.keys(trend_description_codes[this.status]).includes(item.label)
        ) {
          const label =
            trend_description_codes[this.status][item.label][
              `label_${this.language}`
            ];
          accum.push({
            ...item,
            label,
          });
        }
        return accum;
      }, []);
    },

    transformPriority(items) {
      return items.sort((a, b) => a.label - b.label);
    },

    transformBoolean(items) {
      return items.map((item) => ({
        ...item,
        label: boolean_codes[item.label][this.language],
      }));
    },

    // Apply string values to codes in ref. list
    transformReason(items) {
      const year = this.selected_year == 2020 ? 2020 : 2015
      return items.map((item) => ({
        ...item,
        label: trend_reason_codes[year][item.label][`label_${this.language}`],
      }));
    },

    isDisplayed(value) {
      const display = {
        taxonomic: ["2020", "2015", "2010", "2005", "2000"],
        region: ["2020", "2015", "2010", "2005", "2000"],
        origin: ["2020", "2015"],
        rank: ["2020", "2015", "2010", "2005", "2000"],
        trend: ["2020", "2015", "2010", "2005"],
        migratory: ["2020", "2015"],
        priority: ["2020", "2015"],
      };
      return this.selected_year
        ? display[value].includes(String(this.selected_year))
        : false;
    },

    // Sorts string array taking into account french language sort issues.
    sortLanguage(item) {},
  },

  computed: {
    ...mapState(["language", "selected_year", "selected_region"]),
    ...mapGetters(["fieldPrefix"]),

    // current or historical year selected:
    // TODO Move to vuex store - also used in result-item-year
    status() {
      const year = years.find((year) => year.label == this.selected_year);
      const status = year?.status;
      return status;
    },

    // list of regions for dropdown (nl excldued for 2015 +)
    regionList() {
      const year = parseInt(this.selected_year);

      const regionList = regions.reduce((accum, region) => {
        if (region.status === "all")
          accum.push(region[`label_${this.language}`]);
        if (region.status === "current" && year >= 2015)
          accum.push(region[`label_${this.language}`]);
        if (region.status === "historic" && year < 2015)
          accum.push(region[`label_${this.language}`]);
        return accum;
      }, []);

      return regionList;
    }
  },
};
</script>

<style></style>
