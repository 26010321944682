import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VueRouter);

const routes = [
  {
    path: "/:query_language?",
    name: "home",
    props: (route) => ({
      ...route.params,
    }),
    component: Home,
  },
  {
    path: "/:query_language?/species-profile/",
    name: "SpeciesProfile",
    props: (route) => ({
      ...route.params,
    }),
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SpeciesProfile.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
