<template>
    <ais-menu-select :attribute="`region_${language}`" :transform-items="setRegion" :limit="30">
        <select
            class="ais-MenuSelect-select"
            slot-scope="{ items, canRefine, refine }"
            :disabled="!canRefine"
            @change="refine($event.currentTarget.value)"
        >
            <option value="">Canada</option>
            <option
            v-for="item in items"
            :key="item.value"
            :value="item.value"
            :selected="item.isRefined"
            >
            {{ item.label }}
            </option>
        </select>
    </ais-menu-select>
</template>

<script>
export default {
    name: 'RegionSelect',
    props: ['language', 'setRegion']
}
</script>

<style>

</style>