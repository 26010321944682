<template>
  <div>
    <CButton
      :display="['none', 'block']"
      variant="ghost"
      @click="open"
      size="sm"
    >
      {{ label(button_label) }}
    </CButton>
    <c-modal
      :is-open="isOpen"
      :on-close="close"
      :closeOnOverlayClick="true"
      size="2xl"
      is-centered
    >
      <c-modal-content ref="content">
        <c-modal-header>
          <c-box align="center">
            <c-text v-if="selected_region === 'Canada'">{{
              label("bar_graph_title")
            }}</c-text>
            <c-text v-else>{{
              `${label("pie_graph_title")} ${selected_region}`
            }}</c-text>
          </c-box>
        </c-modal-header>
        <c-modal-body>
          <div width="1000px">
            <c-flex
              v-if="loading"
              alignItems="center"
              justifyContent="center"
              height="300px"
            >
              <c-spinner size="xl" />
            </c-flex>
            <vue-apex-charts
              v-else-if="selected_region === 'Canada'"
              :options="chartOptions"
              type="bar"
              :series="baseSeries"
            />
            <vue-apex-charts
              v-else
              :options="pieOptions"
              type="pie"
              :series="pieSeries"
            />
          </div>
        </c-modal-body>
        <c-modal-footer>
          <c-button @click="close">X</c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import { CSpinner } from "@chakra-ui/vue";
import { mapState, mapGetters } from "vuex";
import {
  origin,
  labels,
  original_rank_fields,
  regions,
  years,
  rank_codes,
  index_name,
} from "../config";

import algoliasearch from "algoliasearch";
import { createWidgetMixin } from "vue-instantsearch";
import { connectCurrentRefinements } from "instantsearch.js/es/connectors";
import VueApexCharts from "vue-apexcharts";

const index = algoliasearch(
  "X5NX4OTB3F",
  "74242f5e36cb6258a861c31f49ea3489"
).initIndex(index_name);

export default {
  name: "GraphResults",
  components: {
    VueApexCharts,
    CSpinner,
  },
  props: {
    button_label: {
      type: String,
      default: "Open",
    },
  },
  mixins: [createWidgetMixin({ connector: connectCurrentRefinements })],
  data() {
    return {
      isOpen: false,
      scrollBehavior: "inside",
      labels: labels,
      originCodes: origin,
      rowData: [],
      loading: false,
      fetchDisabled: false,
      baseSeries: [],
      chartOptions: {},
      pieSeries: [],
      pieOptions: {},
    };
  },
  methods: {
    open() {
      this.isOpen = true;
      this.getData();
    },
    close() {
      this.isOpen = false;
    },
    label(label) {
      return this.labels[label][this.language];
    },
    getData() {
      if (!this.isOpen) return;
      this.loading = true;
      this.fetchDisabled = true;
      let hits = [];
      // include text fields for filtering
      return index
        .browseObjects({
          filters: this.filters,
          query: document.getElementsByClassName("ais-SearchBox-input")[0]
            .value,
          batch: (batch) => {
            hits = hits.concat(batch);
          },
        })
        .then(() => {
          this.rowData = hits;
          this.loading = false;
          this.fetchDisabled = false;
          this.createSeries(hits);
        })
        .catch((error) => {});
    },
    createSeries(hits) {
      // Name = rounded national rank
      // for given year, get list of ranks
      // parse rowData into counts for each region
      if (hits.length === 0) {
        return;
      }
      const currentRankCodes = Object.keys(rank_codes[this.historicalStatus]);

      const baseSeries = {};
      for (const code of currentRankCodes) {
        baseSeries[code] = {
          name: code,
          data: new Array(original_rank_fields.length).fill(0),
        };
      }

      this.rowData.forEach((row) => {
        // For each rank field in row, increment the count for that rank in that region in the baseSeries object:
        original_rank_fields.forEach((region, region_index) => {
          const codeForRegion = row[region];
          if (baseSeries[codeForRegion] && codeForRegion !== "0") {
            baseSeries[codeForRegion].data[region_index] += 1;
          }
        });
      });

      // Convert baseseries rank codes to labels based on selected language:
      this.baseSeries = Object.entries(baseSeries)
        .map(([code, data]) => {
          return {
            name:
              rank_codes[this.historicalStatus][code][`label_${this.language}`],
            data: data.data,
            color: rank_codes[this.historicalStatus][code].color,
            order: rank_codes[this.historicalStatus][code].order,
          };
        })
        .sort((a, b) => b.order - a.order);

      this.chartOptions = {
        dataLabels: {
          enabled: false,
        },
        chart: {
          type: "bar",
          height: 500,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          type: "category",
          categories: original_rank_fields.map((item) =>
            item.split("_")[0].toUpperCase()
          ),
          labels: {
            style: {
              fontSize: "11px",
              fontWeight: 200,
            },
          },
        },
        legend: {
          position: "bottom",
          offsetY: 0,
        },
        fill: {
          opacity: 1,
        },
      };

      const regionIndex = this.selected_reggion
        ? original_rank_fields.indexOf(this.selected_region)
        : 0;

      this.pieSeries = this.baseSeries.map((series) => {
        return series.data[regionIndex];
      });

      this.pieOptions = {
        labels: this.baseSeries.map((series) => series.name),
        colors: this.baseSeries.map((series) => series.color),
      };
    },
    origin(value) {
      return value && this.originCodes[value][`label_${this.language}`];
    },
  },
  watch: {
    filters: {
      handler: function(val, oldVal) {
        this.getData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["language", "selected_year", "selected_region"]),
    ...mapGetters(["fieldPrefix"]),
    historicalStatus() {
      return years.find((item) => item.label == this.selected_year).status;
    },
    formattedData() {
      return this.rowData.map((row) => ({
        year: row.year,
        code: row.code,
        common_name: row[`common_name_${this.language}`],
        scientific_name: row.scientific_name,
        taxonomic_group: row[`taxonomic_group_${this.language}`],
        rank: row[`${this.fieldPrefix}_rank`],
        origin: this.origin(row[`${this.fieldPrefix}_origin_code`]),
      }));
    },
    filters() {
      if (!this.state) return {};
      return this.state.items
        .map(({ attribute, refinements }) => {
          return refinements
            .map(
              ({ attriube, value }) => `${attribute}:${JSON.stringify(value)}`
            )
            .join(" AND ");
        })
        .join(" AND ");
    },
  },
};
</script>

<style></style>
