<template>
    <c-flex align="top" width="100%" pb="1" border-bottom="1px solid #dbdbdb" :display="['none', 'none', 'flex']">
        <c-box flexGrow="1">
            <c-flex alignItems="left">
                <c-box :width="['100%', '60%', '60%']">
                    <c-flex :direction="['column', 'row']">

                <c-box>
                    <c-text width="70px" fontSize="md" color="green.400" pr="5">{{ label('year') }}</c-text>
                </c-box>
                <c-box  :width="['60%']">
                        <c-text :fontSize="{base: 'md'}">{{ label('species') }}</c-text>
                </c-box>
                <c-box  :width="['50%']">
                        <c-text :fontSize="{base: 'md'}">{{ label('scientific_name') }}</c-text>
                </c-box>
                    </c-flex>
                </c-box>
                <c-box :width="['100%', '40%', '40%']">
                    <c-flex direction="row">

                    <c-box pr="5" :width="['50%']">
                        <c-text :fontSize="{base: 'md'}">{{ label('taxonomic_group') }}</c-text>
                    </c-box>
                    <c-box :width="['25%']" pr="5" >
                        <c-text  :fontSize="{base: 'md'}">{{ label('rank') }}</c-text>
                    </c-box>
                    <c-box flexGrow="2" pr="5">
                        <c-text :fontSize="{base: 'md'}">{{ label('origin') }}</c-text>
                    </c-box>
                                        </c-flex>
                </c-box>
            </c-flex>
        </c-box>
    </c-flex>
</template>

<script>
import { labels } from '../config'
import { mapState } from 'vuex'
export default {
    methods: {
        label(label) {
            return labels[label][this.language]
        }
    },
    computed: {
        ...mapState(['language'])
    }
}
</script>