<template>
    <CThemeProvider>
      <CColorModeProvider>
        <CBox font-family="body" as="main" >
          <CReset/>
            <ais-instant-search 
              :search-client="searchClient"
              :search-store="searchStore" 
              :index-name="index_name"
              >
              <keep-alive>
                <router-view />
              </keep-alive>
            </ais-instant-search>
          </CBox>
      </CColorModeProvider>
    </CThemeProvider>
</template>

<script>

import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';
import { CThemeProvider, CColorModeProvider,  CBox, CReset } from '@chakra-ui/vue'
import { index_name } from './config';
export default {
  name: "App",
    components: {
    CThemeProvider,
    CColorModeProvider,
    CReset,
    CBox
  },
  data() {
    const vueRouter = this.$router
    return {
      searchClient: algoliasearch(
        'X5NX4OTB3F',
        '517d8fd3237c673c13b8c06a1d1595a3'
      ),
      searchStore: {},
      index_name: index_name,
    }
  },
  methods: {
    
  },
  computed: {
    currentTaxonmic () {
      return this.searchStore
    }
  }
};
</script>
