<template>
  <div>
      <h2 class="c3">D&eacute;finition des rangs</h2>
      <h3>2015 or more recent:</h3>
      <table class="c23">
      <tbody>
        <tr class="c3">
          <td class="c1" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">Rank</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Category</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Description</span></p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c18" colspan="3" rowspan="1">
            <p class="c0"><span class="c19"></span></p>
            <p class="c7"><span class="c21">Geographic scale</span></p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">N</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">National</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Indicates a rank at the national level in Canada.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">S</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Subnational</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Indicates a rank at the level of a province, territory, or
                ocean region in Canada.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c18" colspan="3" rowspan="1">
            <p class="c0"><span class="c19"></span></p>
            <p class="c7"><span class="c21">Conservation status</span></p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c32" colspan="1" rowspan="1">
            <p class="c8"><span class="c20">X</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Presumed Extirpated</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species is believed to be extirpated from the jurisdiction
                (nation, province, territory, or ocean region). Not located
                despite intensive searches of historical sites and other
                appropriate habitat, and virtually no likelihood that it will be
                rediscovered.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c5" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">H</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Possibly Extirpated</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Known from only historical records but still some hope of
                rediscovery. There is evidence that the species may no longer be
                present in the jurisdiction, but not enough to state this with
                certainty. Examples of such evidence include: (1) that a species
                has not been documented in approximately 20-40 years despite
                some searching and/or some evidence of significant habitat loss
                or degradation; (2) that a species has been searched for
                unsuccessfully, but not thoroughly enough to presume that it is
                no longer present in the jurisdiction.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c28" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">1</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Critically Imperiled</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >At very high risk of extirpation in the jurisdiction due to
                very restricted range, very few populations or occurrences, very
                steep declines, severe threats, or other factors.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c27" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">2</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Imperiled</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >At high risk of extirpation in the jurisdiction due to
                restricted range, few populations or occurrences, steep
                declines, severe threats, or other factors.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c15" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">3</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Vulnerable</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >At moderate risk of extirpation in the jurisdiction due to a
                fairly restricted range, relatively few populations or
                occurrences, recent and widespread declines, threats, or other
                factors.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c26" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">4</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Apparently Secure</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >At a fairly low risk of extirpation in the jurisdiction due to
                an extensive range and/or many populations or occurrences, but
                with possible cause for some concern as a result of local recent
                declines, threats, or other factors.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c11" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">5</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Secure</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >At very low or no risk of extirpation in the jurisdiction due
                to a very extensive range, abundant populations or occurrences,
                with little to no concern from declines or threats.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c22" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">U</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Unrankable</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Currently unrankable due to lack of information or due to
                substantially conflicting information about status or
                trends.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c14" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">NR</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Unranked</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >National or subnational conservation status not yet
                assessed.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c17" colspan="1" rowspan="1">
            <p class="c8"><span class="c16">NA</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Not Applicable</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >A conservation status rank is not applicable because the
                species is not a suitable target for conservation activities. It
                includes exotic species (that have been moved beyond their
                natural range as a result of human activity) or accidental
                species (occurring infrequently and unpredictably outside their
                usual range).</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c18" colspan="3" rowspan="1">
            <p class="c0"><span class="c19"></span></p>
            <p class="c7"><span class="c21">Qualifier</span></p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">?</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Inexact Numeric Rank</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Denotes inexact numeric rank. This designation should not be
                used with any of the X, H, U, NR or NA conservation status
                ranks.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">B</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Breeding</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Conservation status refers to the breeding population of the
                species in the nation, province, territory, or ocean
                region.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">N</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Non-breeding</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Conservation status refers to the non-breeding population of
                the species in the nation, province, territory, or ocean
                region.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">M</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c9"><span class="c2">Migrant</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Conservation status refers to the migrant population of the
                species in the nation, province, territory, or ocean
                region.</span
              >
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <h3>2000, 2005 and 2010:</h3>
        <table class="c23">
      <tbody>
        <tr class="c3">
          <td class="c1" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">Rank</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Category</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Description</span></p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c25" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">0.2</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Extinct</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species that are extirpated worldwide (i.e., they no longer
                exist anywhere).</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c31" colspan="1" rowspan="1">
            <p class="c8"><span class="c16">0.1</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Extirpated</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species that are no longer present in a given geographic area,
                but occur in other areas.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c28" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">1</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">At Risk</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species for which a formal, detailed risk assessment (COSEWIC
                status assessment or provincial or territorial equivalent) has
                been completed and that have been determined to be at risk of
                extirpation or extinction (i.e. Endangered or Threatened). A
                COSEWIC designation of Endangered or Threatened automatically
                results in a Canada General Status Rank (Canada rank) of At
                Risk. Where a provincial or territorial formal risk assessment
                finds a species to be Endangered or Threatened in that
                particular region, then, under the general status program, the
                species automatically receives a provincial or territorial
                general status rank of At Risk.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c27" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">2</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">May Be At Risk</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species that may be at risk of extirpation or extinction and
                are therefore candidates for a detailed risk assessment by
                COSEWIC, or provincial or territorial equivalents.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c15" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">3</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Sensitive</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species that are not believed to be at risk of immediate
                extirpation or extinction but may require special attention or
                protection to prevent them from becoming at risk.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c11" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">4</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Secure</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species that are not believed to belong in the categories
                Extinct, Extirpated, At Risk, May Be At Risk, Sensitive,
                Accidental or Exotic. This category includes some species that
                show a trend of decline in numbers in Canada but remain
                relatively widespread or abundant.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c22" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">5</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Undetermined</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species for which insufficient data, information, or knowledge
                is available with which to reliably evaluate their general
                status.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c14" colspan="1" rowspan="1">
            <p class="c8"><span class="c2">6</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Not Assessed</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species that are known or believed to be present regularly in
                the geographic area in Canada to which the rank applies, but
                have not yet been assessed by the general status program.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c17" colspan="1" rowspan="1">
            <p class="c8"><span class="c16">7</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Exotic</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species that have been moved beyond their natural range as a
                result of human activity. In this report, Exotic species have
                been purposefully excluded from all other categories.</span
              >
            </p>
          </td>
        </tr>
        <tr class="c3">
          <td class="c1 c29" colspan="1" rowspan="1">
            <p class="c8"><span class="c30">8</span></p>
          </td>
          <td class="c4" colspan="1" rowspan="1">
            <p class="c7"><span class="c2">Accidental</span></p>
          </td>
          <td class="c6" colspan="1" rowspan="1">
            <p class="c7">
              <span class="c2"
                >Species occurring infrequently and unpredictably, outside their
                usual range.</span
              >
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
 @import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98");
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c1 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 69.2pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c4 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 86.5pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c18 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 388.6pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c6 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 232.9pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c10 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c0 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt;
      }
      .c2 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c16 {
        color: #ffffff;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c19 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Calibri";
        font-style: italic;
      }
      .c7 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c8 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c9 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c23 {
        margin-left: auto;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto;
      }
      .c13 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        text-align: left;
      }
      .c24 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
      }
      .c21 {
        font-style: italic;
      }
      .c17 {
        background-color: #000000;
      }
      .c11 {
        background-color: #339966;
      }
      .c20 {
        color: #ffffff;
      }
      .c5 {
        background-color: #6699ff;
      }
      .c3 {
        height: 0pt;
      }
      .c22 {
        background-color: #c0c0c0;
      }
      .c14 {
        background-color: #808080;
      }
      .c27 {
        background-color: #ff9900;
      }
      .c30 {
        color: #000000;
      }
      .c28 {
        background-color: #ff0000;
      }
      .c12 {
        height: 11pt;
      }
      .c15 {
        background-color: #ffff00;
      }
      .c25 {
        background-color: #3366ff;
      }
      .c26 {
        background-color: #66ff66;
      }
      .c32 {
        background-color: #0000cc;
      }
      .c31 {
        background-color: #800080;
      }
      .c29 {
        background-color: #00ffff;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Calibri";
      }
      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Calibri";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
</style>