export const baseUrl = process.env.VUE_APP_BASE_URL;
export const current_year = "2020";

export const index_name = "gss_wildspecies_2020";

export const years = [
  // { label: 2025, status: "c" },
  { label: 2020, status: "c" },
  { label: 2015, status: "c" },
  { label: 2010, status: "h" },
  { label: 2005, status: "h" },
  { label: 2000, status: "h" },
];

export const origin = {
  NAT: { label_en: "Native", label_fr: "Indigène" },
  EXO: { label_en: "Exotic", label_fr: "Exotique" },
};

export const rank_codes = {
  c: {
    "1": {
      label_en: "Critically Imperiled",
      label_fr: "Gravement en péril",
      color: "#FF0000",
      order: 2,
    },
    "2": {
      label_en: "Imperiled",
      label_fr: "En péril",
      color: "#FF9900",
      order: 3,
    },
    "3": {
      label_en: "Vulnerable",
      label_fr: "Vulnérable",
      color: "#FFFF00",
      order: 4,
    },
    "4": {
      label_en: "Apparently Secure",
      label_fr: "Apparemment en sécurité",
      color: "#66FF66",
      order: 5,
    },
    "5": {
      label_en: "Secure",
      label_fr: "En sécurité",
      color: "#339966",
      order: 6,
    },
    H: {
      label_en: "Possibly Extirpated",
      label_fr: "Possiblement disparue",
      color: "#6699FF",
      order: 1,
    },
    A: {
      label_en: "Not Applicable",
      label_fr: "Non applicable",
      color: "#000000",
      order: 9,
    },
    "0": {
      label_en: "Not Present",
      label_fr: "Absente",
      color: "#FFFFFF",
      order: 10,
    },
    R: {
      label_en: "Unranked",
      label_fr: "Non classée",
      color: "#808080",
      order: 8,
    },
    U: {
      label_en: "Unrankable",
      label_fr: "Inclassable",
      color: "#C0C0C0",
      order: 7,
    },
    X: {
      label_en: "Presumed Extirpated",
      label_fr: "Présumée disparue",
      color: "#0000CC",
      order: 0,
    },
    // '': {"label_en": '', 'label_fr': ''}
  },

  h: {
    "0.1": {
      label_en: "Extirpated",
      label_fr: "Disparue de la région",
      color: "#800080",
      order: 1,
    },
    "1": {
      label_en: "At Risk",
      label_fr: "En péril",
      color: "#FF0000",
      order: 2,
    },
    "2": {
      label_en: "May Be At Risk",
      label_fr: "Possiblement en péril",
      color: "#FF9900",
      order: 3,
    },
    "3": {
      label_en: "Sensitive",
      label_fr: "Sensible",
      color: "#FFFF00",
      order: 4,
    },
    "4": {
      label_en: "Secure",
      label_fr: "En sécurité",
      color: "#339966",
      order: 5,
    },
    "5": {
      label_en: "Undetermined",
      label_fr: "Indéterminée",
      color: "#C0C0C0",
      order: 6,
    },
    "6": {
      label_en: "Not Assessed",
      label_fr: "Non évaluée",
      color: "#808080",
      order: 7,
    },
    "7": {
      label_en: "Exotic",
      label_fr: "Exotique",
      color: "#000000",
      order: 8,
    },
    "8": {
      label_en: "Accidental",
      label_fr: "Occasionnelle",
      color: "#00FFFF",
      order: 9,
    },
    NP: {
      label_en: "Not Present",
      label_fr: "Absente",
      color: "#FFFFFF",
      order: 10,
    },
    "0.2": {
      label_en: "Extinct",
      label_fr: "Disparue",
      color: "#3366FF",
      order: 0,
    },
    // '': {"label_en": '', 'label_fr': ''}
  },
};

export const boolean_codes = {
  Yes: { en: "Yes", fr: "Oui" },
  No: { en: "No", fr: "Non" },
};

export const trend_description_codes = {
  c: {
    CHG: {
      label_en: "Changed from or to ranks U, NR, NA.",
      label_fr: "Déplacée des rangs U, NR, NA.",
    },
    DEL: { label_en: "Deleted species.", label_fr: "Espèce effacée." },
    INC: {
      label_en: "Increased level of risk.",
      label_fr: "Niveau de risque plus élevé.",
    },
    NEW: { label_en: "New species.", label_fr: "Nouvelle espèce." },
    RED: {
      label_en: "Reduced level of risk.",
      label_fr: "Niveau de risque moins élevé.",
    },
  },
  h: {
    CHG: {
      label_en: "Changed from or to ranks 5, 6, 7, 8",
      label_fr: "Déplacée des rangs 5, 6, 7, 8",
    },
    DEL: { label_en: "Deleted species.", label_fr: "Espèce effacée." },
    INC: {
      label_en: "Increased level of risk.",
      label_fr: "Niveau de risque plus élevé.",
    },
    NEW: { label_en: "New species.", label_fr: "Nouvelle espèce." },
    RED: {
      label_en: "Reduced level of risk.",
      label_fr: "Niveau de risque moins élevé.",
    },
  },
};

export const trend_reason_codes = {
  2020: {
    G: {
      label_en: "Genuine change in status.",
      laber_fr: "Changement authentique dans le statut.",
    },
    I: {
      label_en: "New information not reflecting genuine change.",
      label_fr:
        "Nouvelles informations ne reflétant pas un changement authentique.",
    },
    N: {
      label_en: "New interpretation of same information.",
      label_fr: "Nouvelle interprétation de la même information.",
    },
    D: {
      label_en: "Incorrect data used previously.",
      label_fr: "Données incorrectes utilisées précédemment.",
    },
    T: {
      label_en: "Taxonomic level change only.",
      label_fr: "Changement au niveau taxonomique seulement.",
    },
    C: { label_en: "Criteria revision.", label_fr: "Changement des critères." },
    O: {
      label_en: "Other or unknown (used only in 2005).",
      label_fr: "Autre ou inconnue (utilisée seulement en 2005).",
    },
  },

  2015: {
    D: {
      label_en: "Incorrect data used previously.",
      label_fr: "Données incorrectes utilisées précédemment.",
    },
    C: {
      label_en: "New COSEWIC assessment.",
      label_fr: "Nouvelle évaluation du COSEPAC.",
    },
    E: {
      label_en: "Error in previous rank.",
      label_fr: "Erreur dans le rang précédent.",
    },
    I: {
      label_en: "Improved knowledge of the species.",
      label_fr: "Amélioration des connaissances sur l’espèce.",
    },
    N: { label_en: "Not available.", label_fr: "Non disponible." },
    P: { label_en: "Procedural change.", label_fr: "Changement procédural." },
    T: { label_en: "Taxonomic change.", label_fr: "Changement taxonomique." },
    B: {
      label_en:
        "Biological change in the population size, distribution, or threats of the species.",
      label_fr:
        "Changement biologique de la taille de la population, de la répartition, ou des menaces de l’espèce.",
    },
  },
};

export const regions = [
  {
    label_en: "Canada",
    label_fr: "Canada",
    status: "all",
    prefix: "ca",
  },
  {
    label_en: "Alberta",
    label_fr: "Alberta",
    status: "all",
    prefix: "ab",
  },
  {
    label_en: "Atlantic Ocean",
    label_fr: "Océan Atlantique",
    status: "all",
    prefix: "atl",
  },
  {
    label_en: "British Columbia",
    label_fr: "Colombie-Britannique",
    status: "all",
    prefix: "bc",
  },
  {
    label_en: "Eastern Arctic Ocean",
    label_fr: "Océan Arctique Est",
    status: "all",
    prefix: "eao",
  },
  {
    label_en: "Labrador",
    label_fr: "Labrador",
    status: "current",
    prefix: "lb",
  },
  {
    label_en: "Manitoba",
    label_fr: "Manitoba",
    status: "all",
    prefix: "mb",
  },
  {
    label_en: "New Brunswick",
    label_fr: "Nouveau-Brunswick",
    status: "all",
    prefix: "nb",
  },
  {
    label_en: "Newfoundland",
    label_fr: "Terre-Neuve",
    status: "current",
    prefix: "nf",
  },
  {
    label_en: "Newfoundland and Labrador",
    label_fr: "Terre-Neuve-et-Labrador",
    status: "historic",
    prefix: "nl",
  },
  {
    label_en: "Northwest Territories",
    label_fr: "Territoires du Nord-Ouest",
    status: "all",
    prefix: "nt",
  },
  {
    label_en: "Nova Scotia",
    label_fr: "Nouvelle-Écosse",
    status: "all",
    prefix: "ns",
  },
  {
    label_en: "Nunavut",
    label_fr: "Nunavut",
    status: "all",
    prefix: "nu",
  },
  {
    label_en: "Ontario",
    label_fr: "Ontario",
    status: "all",
    prefix: "on",
  },
  {
    label_en: "Pacific Ocean",
    label_fr: "Océan Pacifique",
    status: "all",
    prefix: "pac",
  },
  {
    label_en: "Prince Edward Island",
    label_fr: "Île-du-Prince-Édouard",
    status: "all",
    prefix: "pe",
  },
  {
    label_en: "Quebec",
    label_fr: "Québec",
    status: "all",
    prefix: "qc",
  },
  {
    label_en: "Saskatchewan",
    label_fr: "Saskatchewan",
    status: "all",
    prefix: "sk",
  },
  {
    label_en: "Western Arctic Ocean",
    label_fr: "Océan Arctique Ouest",
    status: "all",
    prefix: "wao",
  },
  {
    label_en: "Yukon",
    label_fr: "Yukon",
    status: "all",
    prefix: "yt",
  },
];

export const labels = {
  higher_classification: {
    en: "Higher Classification",
    fr: "Classification supérieure",
  },
  graph_results: {
    en: "Graph results of ranks",
    fr: "Graphique des résultats",
  },

  bar_graph_title: {
    en: "Search results of species by rank",
    fr: "Résultats de la recherche par rang",
  },

  pie_graph_title: {
    en: "Search results by rank in ",
    fr: "Résultats de la recherche par rang en ",
  },

  rank_definition: {
    en: "Definition of the ranks",
    fr: "Définition des rangs",
  },

  logo_src: {
    en: "./logo_en.jpeg",
    fr: "./logo_fr.jpeg",
  },

  wildspecies_uri: {
    en: "https://www.wildspecies.ca/welcome",
    fr: "https://www.wildspecies.ca/fr/bienvenue",
  },

  see_all: {
    en: "See all",
    fr: "Voir toutes",
  },

  not_present: {
    en: "Not Present",
    fr: "Absente",
  },

  show_more: {
    en: "Show more",
    fr: "Voir plus",
  },

  show_less: {
    en: "Show less",
    fr: "Voir moins",
  },

  map_disclaimer: {
    en: "The distribution is complete based on the best available knowledge.",
    fr:
      "La répartition est complète selon les meilleures connaissances disponibles.",
  },

  present_in: {
    en: "Present in",
    fr: "Présente dans",
  },

  migratory_sp: {
    en: "Migratory species:",
    fr: "Espèce migratrice:",
  },

  regions: {
    en: "regions",
    fr: "régions",
  },

  region_singular: {
    en: "region",
    fr: "région",
  },

  download: {
    en: "Download",
    fr: "Télécharger",
  },

  search: {
    en: "Search",
    fr: "Rechercher",
  },

  but_exotic: {
    en: "but exotic in:",
    fr: "mais exotique dans:",
  },

  not_at_risk: {
    en: "This species is not designated as may be at risk",
    fr: "Cette espèce n’est pas désignée comme étant possiblement en péril",
  },

  scientific_name: {
    en: "Scientific Name",
    fr: "Nom scientifique",
  },

  species: {
    en: "Species",
    fr: "Espèce",
  },

  year: {
    en: "Year",
    fr: "Année",
  },

  access_nse: {
    en: "Access other data on this species on the NatureServe Explorer.",
    fr:
      "Accédez à d'autres données sur cette espèce dans l'explorateur NatureServe.",
  },

  search_ws: {
    en: "Search the Wild Species Reports: ",
    fr: "Rechercher les rapports Espèces sauvages",
  },

  no_results: {
    en: "No results found",
    fr: "Aucun résultats trouvé",
  },

  results: {
    en: "results",
    fr: "résultats",
  },

  taxonomic_group: {
    en: "Taxonomic group",
    fr: "Groupe taxonomique",
  },

  region: {
    en: "Region",
    fr: "Region",
  },

  rank: {
    en: "Rank",
    fr: "Rang",
  },

  origin: {
    en: "Origin",
    fr: "Origine",
  },

  priority: {
    en: "Priority",
    fr: "Prioritié",
  },

  description: {
    en: "Description of the Change",
    fr: "Description du Changement",
  },

  migratory: {
    en: "Migratory",
    fr: "Migratrice",
  },

  reason: {
    en: "Reason for Change",
    fr: "Raison du Changement",
  },

  trend: {
    en: "Trend",
    fr: "Tendance",
  },

  search_placeholder: {
    en: "Search by species, classification, or General Status code",
    fr:
      "Recherche par espèce, classification, ou code de la situation générale",
  },

  report_year: {
    en: "Select a report year to refine your search",
    fr: "Sélectionnez une année du rapport pour raffiner votre recherche",
  },

  select_region: {
    en: "Select Region",
    fr: "Choisissez une région",
  },

  general_status: {
    en: "General Status in Canada in",
    fr: "Situation générale au Canada en",
  },

  home: {
    en: "Home",
    fr: "Accueil",
  },

  species_code: {
    en: "Species code",
    fr: "Code de l'espèce",
  },

  this_species_is: {
    en: "This species is ",
    fr: "Cette espèce est ",
  },

  in_canada: {
    en: "in Canada",
    fr: "au Canada",
  },

  learn_species: {
    en: "Learn more about all species of ",
    fr: "Apprenez-en davantage sur toutes les espèces de ",
  },

  status_disclaimer: {
    en:
      "The General Status ranks do not have legal weight. They are meant to identify the species that may be at risk in Canada.",
    fr:
      "Les rangs de la situation générale n'ont pas de valeur légale. Ils servent à identifier les espèces qui sont possiblement en péril au Canada.",
  },

  species_details: {
    en: "Species Details",
    fr: "Détails de l'espèce",
  },

  common_names: {
    en: "Common Names",
    fr: "Noms communs",
  },

  en_common_name: {
    en: "English common name: ",
    fr: "Nom commun en anglais: ",
  },
  en_common_name_used: {
    en: "English common names used",
    fr: "Noms communs utilisés en anglais",
  },

  fr_common_name: {
    en: "French common name:",
    fr: "Nom commun en français:",
  },

  fr_common_name_used: {
    en: "French common names used",
    fr: "Noms communs utilisés en français",
  },

  scientific_names: {
    en: "Scientific Names",
    fr: "Noms scientifiques",
  },

  family: {
    en: "Family",
    fr: "Famille",
  },

  order: {
    en: "Order",
    fr: "Ordre",
  },

  priority_score: {
    en: "Priority Score",
    fr: "Pointage prioritaire",
  },

  perc_canada: {
    en: "Approximate percentage of world range in Canada: ",
    fr: "Pourcentage approximatif de la répartition mondiale au Canada: ",
  },

  priority_score_explain: {
    en: "Priority score (1 is highest, 10 is lower):",
    fr: "Pointage prioritaire (1 est le plus élevé, 10 est plus faible):",
  },

  rounded_national_rank: {
    en: "Rounded National Rank",
    fr: "Rang national arrondi",
  },

  desc_change: {
    en: "Description of the change",
    fr: "Description du changement",
  },

  reason_change: {
    en: "Reason for change",
    fr: "Raison du changement",
  },

  orig_ranks: {
    en: "Original Ranks: ",
    fr: "Rangs originaux: ",
  },

  assessed: {
    en: "Species assessed by COSEWIC:",
    fr: "Espèce évaluée par le COSEPAC:",
  },

  not_assessed: {
    en: "This species was not assessed by COSEWIC",
    fr: "Cette espèce n’a pas été évaluée par le COSEPAC",
  },

  cosewic_link: {
    en: "Link to COSEWIC assessment",
    fr: "Lien vers l’évaluation du COSEPAC",
  },

  trend_rank: {
    en: "Trends in rounded national ranks",
    fr: "Tendances dans les rangs nationaux arrondis",
  },

  header_title: {
    en: "Wild Species: The General Status of Species in Canada",
    fr: "Espèces sauvages: la situation générale des espèces au Canada",
  },

  why_at_risk: {
    en: "Why the species may be at risk:",
    fr: "Pourquoi l’espèce est possiblement en péril:",
  },
};

export const map_rank_color = {
  c: {
    "1": { color: "#FF0000", color_scheme: "red", font_color: "black" },
    "2": { color: "#FF9900", color_scheme: "orange", font_color: "black" },
    "3": { color: "#FFFF00", color_scheme: "yellow", font_color: "black" },
    "4": { color: "#66FF66", color_scheme: "vue", font_color: "black" },
    "5": { color: "#339966", color_scheme: "green", font_color: "black" },
    H: { color: "#6699FF", color_scheme: "blue.200", font_color: "black" },
    NA: { color: "#000000", color_scheme: "black", font_color: "white" },
    NP: { color: "#FFFFFF", color_scheme: "white", font_color: "black" },
    NR: { color: "#808080", color_scheme: "gray.200", font_color: "black" },
    U: { color: "#C0C0C0", color_scheme: "gray.500", font_color: "black" },
    X: { color: "#0000CC", color_scheme: "blue.500", font_color: "white" },
    "": { color: "black", color_scheme: "black" },
  },
  h: {
    "0.1": { color: "#800080", color_scheme: "indigo", font_color: "white" },
    "1": { color: "#FF0000", color_scheme: "red", font_color: "black" },
    "2": { color: "#FF9900", color_scheme: "orange", font_color: "black" },
    "3": { color: "#FFFF00", color_scheme: "yellow", font_color: "black" },
    "4": { color: "#339966", color_scheme: "green", font_color: "black" },
    "5": { color: "#C0C0C0", color_scheme: "gray", font_color: "black" },
    "6": { color: "#808080", color_scheme: "gray", font_color: "black" },
    "7": { color: "#000000", color_scheme: "black", font_color: "white" },
    "8": { color: "#00FFFF", color_scheme: "cyan", font_color: "black" },
    NP: { color: "#FFFFFF", color_scheme: "white", font_color: "black" },
    "0.2": { color: "#3366FF", color_scheme: "blue", font_color: "black" },
    "": { color: "black", color_scheme: "black" },
  },
};

export const original_rank_fields = [
  "ca_map_rank",
  "yt_map_rank",
  "nt_map_rank",
  "nu_map_rank",
  "bc_map_rank",
  "ab_map_rank",
  "sk_map_rank",
  "mb_map_rank",
  "on_map_rank",
  "qc_map_rank",
  "nb_map_rank",
  "ns_map_rank",
  "pe_map_rank",
  "nl_map_rank",
  "lb_map_rank",
  "nf_map_rank",
  "pac_map_rank",
  "wao_map_rank",
  "eao_map_rank",
  "atl_map_rank",
];
